import router from '@/router';
import { API }  from '@/inc/api';
import { _st } from '@/softech';

export const auth = {
    namespaced: true,
    state: {
        userId      : null,
        user        : null,
        locationId  : null,
        location    : null,
        authToken   : null,
    },
    mutations: {
        setUserId( state, payload ) {
            state.userId = payload;
        },
        setUser( state, payload ) {
            state.user = payload;
        },
        setLocationId( state, payload ) {
            state.locationId = payload;
        },
        setLocation( state, payload ) {
            state.location = payload;
        },
        setToken( state, payload ) {
            state.authToken = payload;
        },
    },
    actions: {
        async login({ dispatch }, { username, password }) {
            try {
                let api = new API();
                const res = await api.signIn({ username, password });

                if( res.data.status === true && res.data.pwdChange === false) {
                    localStorage.setItem('auth', res.data.token);
                    await dispatch('authAction');
                    router.push('/dashboard');
                }

                return Promise.resolve( res.data );
            }
            catch(error) {
                console.log( error );
                return Promise.reject( error );
            }
        },
        logout({ commit }) {
            try {
                commit('setUserId', null);
                commit('setUser', null);
                commit('setLocation', null);
                commit('setToken', null);
                
                localStorage.removeItem('auth');

                router.push('/');
                return Promise.resolve();
            }
            catch(error) {
                console.log( error );
                return Promise.reject( error );
            }
        },
        async forgotPassword(_, { username }) {
            try {
                let api = new API();
                const res = await api.forgotPassword( username );
                return Promise.resolve( res );
            }
            catch(error) {
                console.log( error )
                return Promise.reject( error );
            }
        },
        async changePassword(_, { username, oldPassword, newPassword }) {
            try {
                let api = new API();
                const res = await api.resetPassword({ username, oldPassword, newPassword });

                return Promise.resolve( res );
            }
            catch(error) {
                console.log( error );
                return Promise.reject( error );
            }
        },
        async authAction({ commit }) {
            let token = localStorage.getItem('auth');
            if( _st.isNUE( token ) ) {
                return Promise.resolve();
            }

            var jwt = require('jsonwebtoken');
            let decoded = jwt.decode( token, {complete: true} );

            commit('setUserId', decoded.payload.usr);
            commit('setLocationId', decoded.payload.loc);
            commit('setToken', token);

            let api = new API();
            let res = await api.getUser( decoded.payload.usr );

            if( res.data.status === true ) {
                commit('setUser', res.data.data);
            }

            res = await api.get( `/admin/location-info/${decoded.payload.loc}` );
            if( res.data.status === true ) {
                commit('setLocation', res.data.data);
                if( res.data.data.blockMissingPayment ) {
                    router.push('/settings');
                }
            }
        },
    },
    getters: {
        user            : (state) => state.user,
        location        : (state) => state.location,
        token           : (state) => state.authToken,
        isLoggedIn      : (state) => !_st.isNUE( state.authToken ),
    }
}