// import { _st } from '@/softech';
import store from '@/store';
import axios from 'axios';

export class API {
    constructor() {
        this.baseURL            = process.env.NODE_ENV === 'production' ? 'https://api.softechpr.com/myurb/v1/index.php' : 'http://10.5.18.101/urbi/api/v1/index.php';
        this.withCredentials    = false;
        this.headers            = this._getHeaders(); 
        this.http               = this._getAxios();
    }

    async signIn({ username, password }) {
        try {
            let res = await this.http.post('/admin/signin', {
                username,
                password
            });

            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async forgotPassword( username ) {
        try {
            let res = await this.http.post('/admin/forgot-pwd', {
                username
            });

            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async resetPassword({ username, oldPassword, newPassword }) {
        try {
            let res = await this.http.post('/admin/reset-pwd', {
                username,
                oldPassword,
                newPassword
            });

            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getUser( userId ) {
        try {
            let res = await this.http.get(`/admin/user/${userId}`);
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getDelinquentUnits() {
        try {
            let res = await this.http.get( '/admin/units/delinquent');
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getAmenities() {
        try {
            let res = await this.http.get( '/admin/amenities');
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getEvents() {
        try {
            let res = await this.http.get( '/admin/events');
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getDcQuota( timeframe) {
        try {
            let res = await this.http.get(`/admin/dc/quota/${timeframe}`);
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getDcUnits() {
        try {
            let res = await this.http.get('/admin/dc/units');
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async getDcResidents() {
        try {
            let res = await this.http.get('/admin/dc/residents');
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async get( endpoint ) {
        try {
            let res = await this.http.get( endpoint );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async post( endpoint, data = null ) {
        try {
            let res = await this.http.post( endpoint, data );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async put( endpoint, data = null ) {
        try {
            let res = await this.http.put( endpoint, data );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    async delete( endpoint ) {
        try {
            let res = await this.http.delete( endpoint );
            return Promise.resolve( res );
        }
        catch( error ) {
            return Promise.reject( error );
        }
    }

    _getHeaders() {
        let auth = '6c4d0d00-3074-11e9-9130-000c29c44ec0';
        if( store.getters['auth/isLoggedIn'] ) {
            auth += `,Bearer ${store.getters['auth/token']}` 
        }

        return {
            Authorization: auth
        };
    }

    _getAxios() {
        return axios.create({
            baseURL         : this.baseURL,
            withCredentials : this.withCredentials,
            headers         : this.headers,
            validateStatus(status) {
                if (status >= 200 && status < 300 && status !== 204) {
                    return true;
                } 
                
                return false;
            }
        });
    }
}