<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="500" max-width="800" persistent>
                <v-card flat ref="card">
                    <v-card-title>{{ dialogTitle }}</v-card-title>
                    <v-card-subtitle>{{ dialogSubtitle }}</v-card-subtitle>
                    <v-card-text>
                        <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                            <span class="warning--text">{{ alertMessage }}</span>
                        </v-alert>
                        <v-row>
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="local.firstName" label="First Name" :error-messages="firstNameError"></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="local.lastName" label="Last Name" :error-messages="lastNameError"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="local.email" label="Email" :error-messages="emailError"></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="local.phone" label="Phone" :error-messages="phoneError" v-mask="'(###) ###-####'"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-select v-model="local.unitType" :items="unitTypeItems" item-value="id" item-text="text" label="Unit Type" :error-messages="unitTypeError"></v-select>
                            </v-col>
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-text-field v-model="local.unitNumber" label="Unit Number" :error-messages="unitNumberError"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">Cancel</v-btn>
                        <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <v-card flat class="ma-0 pa-0" v-else>
            <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                <span class="warning--text">{{ alertMessage }}</span>
            </v-alert>
            <v-card-text class="ma-0 pa-0">
                <v-row>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field v-model="local.firstName" label="First Name" :error-messages="firstNameError"></v-text-field>
                    </v-col>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field v-model="local.lastName" label="Last Name" :error-messages="lastNameError"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field v-model="local.email" label="Email" :error-messages="emailError"></v-text-field>
                    </v-col>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field v-model="local.phone" label="Phone" :error-messages="phoneError" v-mask="'(###) ###-####'"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-select v-model="local.unitType" :items="unitTypeItems" item-value="id" item-text="text" label="Unit Type" :error-messages="unitTypeError"></v-select>
                    </v-col>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <v-text-field v-model="local.unitNumber" label="Unit Number" :error-messages="unitNumberError"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancel">Cancel</v-btn>
                <v-btn text color="primary" @click="save" :loading="saving">{{ local.id == 0 ? 'Save' : 'update' }}</v-btn>
            </v-card-actions>
        </v-card>    
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask'
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'New Resident'
        },
        dialogSubtitle: {
            type: String,
            default: 'Add new resident to your community'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        firstNameError: '',
        lastNameError: '',
        emailError: '',
        phoneError: '',
        unitTypeError: '',
        unitNumberError: '',
        unitTypeItems: [
            { id: 1, text: 'House' },
            { id: 2, text: 'Apartment' },
        ],
        local: {
            id: 0,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            unitType: 1,
            unitNumber: '',
        },
        saving: false,
        showAlert: false,
        alertMessage: '',
        alertColor: '',
    }),
    computed: {
        ...mapGetters({
            locationId: 'auth/locationId'
        }),
        resident() {
            return {
                id: this.local.id,
                firstName: this.local.firstName,
                lastName: this.local.lastName,
                fullName: this.local.firstName.trim() + ' ' + this.local.lastName.trim(),
                email: this.local.email,
                phone: this.local.phone,
                unitType: this.local.unitType,
                unitNumber: this.local.unitNumber
            }
        }
    },
    watch: {
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
        },
        cancel() {
            this.$emit('cancel');
            this.local = {
                id: 0,
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                unitType: 2,
                unitNumber: '',
            };
            this.firstNameError = this.lastNameError = this.emailError = this.phoneError = this.unitNumberError = '';
            this.showAlert = false;
        },
        isValid() {
            let errorFound = false;
            this.firstNameError = this.lastNameError = this.emailError = this.phoneError = this.unitNumberError = '';

            if( this.local.firstName.length == 0 ) {
                this.firstNameError = 'required';
                errorFound = true;
            }
            if( this.local.lastName.length == 0 ) {
                this.lastNameError = 'required';
                errorFound = true;
            }
            if( this.local.email.length == 0 || !/.{2,30}@.{2,30}\..{2,4}/g.test( this.local.email ) ) {
                this.emailError = 'must be a valid email address';
                errorFound = true;
            }
            
            let tmpPhone = this.local.phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
            if( tmpPhone.length != 10 ) {
                this.phoneError = 'must be a valid phone number';
                errorFound = true;
            }
            if( this.local.unitNumber.length == 0 ) {
                this.unitNumberError = 'required';
                errorFound = true;
            }

            return !errorFound;
        },
        load(r) {
            this.local = {
                id: r.id,
                firstName: r.firstName,
                lastName: r.lastName,
                email: r.email,
                phone: r.phone,
                unitType: parseInt( r.buildingTypeId ),
                unitNumber: r.unitNumber,
            };
        },
        async save() {
            if( !this.isValid() )
                return;

            try {
                let tmpLocal = JSON.parse( JSON.stringify( this.local ) );
                tmpLocal.phone = tmpLocal.phone.replace('(', '').replace(')', '').replace('-', '').replace(' ', '')

                let api = new API();

                this.saving = true;
                
                let res = null;
                if( _st.isNUE( this.local.id ) ) {
                    res = await api.post('/admin/resident', tmpLocal );
                }
                else {
                    res = await api.put('/admin/resident', tmpLocal );
                }

                this.saving = false;
                if( !res.data.status && res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'error';
                    this.showAlert = true;
                    return;
                }

                if( !res.data.status && !res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'warning';
                    this.showAlert = true;
                    return;
                }

                // is an update
                if( this.local.id != 0 ) {
                    this.$emit('update', res.data.data);
                    this.local.id = res.data.data.id;
                    this.cancel();
                    return;
                }

                this.$emit('saved', res.data.data);
                this.local.id = res.data.data.id;

                // show confirmation message
                
                this.cancel();
            }
            catch(error) {
                this.saving = false;
                console.log(error);
            }
        },
    },
    directives: {
      mask,
    },
}
</script>

<style scoped>

</style>