<template>
    <div>
        <div v-if="dialog">
            <v-dialog v-model="dialogOpen" min-width="500" max-width="800" persistent>
                <v-card flat ref="card">
                    <v-card-title>Apply Payment</v-card-title>
                    <v-card-subtitle>Register a new payment to the resident</v-card-subtitle>
                    <v-card-text>
                        <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                            <span class="warning--text">{{ alertMessage }}</span>
                        </v-alert>
                        <v-row class="mx-0">
                            <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mx-0">
                                <v-row>
                                    <v-autocomplete label="Unit" :items="units" :filter="customFilter" @change="loadFromServer" :error-messages="unitError">
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ item.unitNumber }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ item.buildingType }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                        <template v-slot:selection="data">
                                            {{ data.item.unitNumber }}
                                        </template>
                                    </v-autocomplete>

                                    <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="payment.date" label="Date" append-icon="mdi-calendar" @blur="payment.date = parseDate(dateFormatted)" v-on="on" :error-messages="dateError"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="payment.date" no-title @input="datemenu = false"></v-date-picker>
                                    </v-menu>

                                    <v-select v-model="payment.method" :items="paymentMethods" label="Payment Method" :error-messages="paymentMethodError"></v-select>

                                    <v-text-field v-model="payment.confirmation" label="Confirmation Number" :error-messages="confirmationError"></v-text-field>
                                </v-row>
                            </v-col>

                            <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mx-0">
                                <div v-if="applyToError.length > 0" class="error--text">{{ applyToError }}</div>
                                <span v-show="paymentsAvailable.length == 0">No payment selected</span>
                                <div v-show="paymentsAvailable.length > 0">
                                    <v-simple-table fixed-header height="250px">
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th><v-icon :color="payment.applyTo.length > 0 ? 'indigo darken-4' : ''" @click="toggleSelectAll">{{ icon }}</v-icon></th>
                                                    <th class="text-left">Payment</th>
                                                    <th class="text-right">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="item in paymentsAvailable" :key="item.id">
                                                    <td><v-icon :color="isSelected(item.id) ? 'primary' : ''" @click="toggleItem(item.id)">{{ isSelected(item.id) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></td>
                                                    <td>{{ item.dueDateMonthYear }}</td>
                                                    <td class="text-right">{{ formatMoney( item.amount ) }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <v-simple-table>
                                        <tfoot>
                                            <tr>
                                                <th class="text-left subtitle-1" colspan="2">Total</th>
                                                <th class="text-right subtitle-1">{{ formatMoney(totalPayment) }}</th>
                                            </tr>
                                        </tfoot>
                                    </v-simple-table>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">Cancel</v-btn>
                        <v-btn text color="primary" @click="processPayment">Process</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

        <v-card flat class="ma-0 pa-0" v-else>
            <v-card-text class="ma-0 pa-0">
                <v-alert v-model="showAlert" :color="alertColor" border="left" colored-border dense>
                    <span class="warning--text">{{ alertMessage }}</span>
                </v-alert>
                <v-row>
                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mx-0">
                        <v-row>
                            <v-autocomplete label="Unit" :items="units" :filter="customFilter" @change="loadFromServer" :error-messages="unitError">
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.unitNumber }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.buildingType }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                                <template v-slot:selection="data">
                                    {{ data.item.unitNumber }}
                                </template>
                            </v-autocomplete>

                            <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="payment.date" label="Date" append-icon="mdi-calendar" @blur="payment.date = parseDate(dateFormatted)" v-on="on" ></v-text-field>
                                </template>
                                <v-date-picker v-model="payment.date" no-title @input="datemenu = false"></v-date-picker>
                            </v-menu>

                            <v-select v-model="payment.method" :items="paymentMethods" label="Payment Method"></v-select>

                            <v-text-field v-model="payment.confirmation" label="Confirmation Number"></v-text-field>
                        </v-row>
                    </v-col>

                    <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mx-0">
                        <span v-show="paymentsAvailable.length == 0">No payment selected</span>
                        <div v-show="paymentsAvailable.length > 0">
                            <v-simple-table fixed-header height="250px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th><v-icon :color="payment.applyTo.length > 0 ? 'indigo darken-4' : ''" @click="toggleSelectAll">{{ icon }}</v-icon></th>
                                            <th class="text-left">Payment</th>
                                            <th class="text-right">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in paymentsAvailable" :key="item.id">
                                            <td><v-icon :color="isSelected(item.id) ? 'primary' : ''" @click="toggleItem(item.id)">{{ isSelected(item.id) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}</v-icon></td>
                                            <td>{{ item.dueDateMonthYear }}</td>
                                            <td class="text-right">{{ formatMoney( item.amount ) }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-simple-table>
                                <tfoot>
                                    <tr>
                                        <th class="text-left subtitle-1" colspan="2">Total</th>
                                        <th class="text-right subtitle-1">{{ formatMoney(totalPayment) }}</th>
                                    </tr>
                                </tfoot>
                            </v-simple-table>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closePaymentDialog">Cancel</v-btn>
                <v-btn text color="primary" @click="processPayment">Process</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    props: {
        dialog: {
            type: Boolean,
            default: true
        },
        dialogTitle: {
            type: String,
            default: 'Apply Payment'
        },
        dialogSubtitle: {
            type: String,
            default: 'Register a new payment to a resident'
        },
        dialogOpen: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        residentId: 0,
        unit: null,
        units: [],
        paymentsAvailable: [],
        payment: {
            date: new Date().toISOString().substr(0, 10),
            method: '',
            confirmation: '',
            applyTo: [],
            amount: 0,
        },
        datemenu: false,
        dateFormatted: '',
        paymentMethods: [
            'ATH Movil', 'Cash', 'Check', 'Money Order', 'American Express', 'Master Card', 'Visa'
        ],
        saving: false,
        showAlert: false,
        alertMessage: '',
        alertColor: '',
        unitError: '',
        dateError: '',
        paymentMethodError: '',
        confirmationError: '',
        applyToError: '',
    }),
    computed: {
        totalAmount() {
            let total = 0;
            this.paymentsAvailable.forEach(p => {
                total += parseFloat( p.amount );
            });

            return total;
        },
        totalPayment() {
            let total = 0;
            this.payment.applyTo.forEach(p => {
                total += parseFloat( p.amount );
            });

            return total;
        },
        allPaymentsSelected() {
            return this.payment.applyTo.length === this.paymentsAvailable.length;
        },
        icon() {
            if (this.allPaymentsSelected) return 'mdi-close-box';
            if (this.payment.applyTo.length > 0 && this.payment.applyTo.length < this.paymentsAvailable.length) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                let api = new API();

                this.loaded = false;
                let res = await api.get( 'admin/residents/units' );
                this.loaded = true;

                if( !res.data.status && res.data.error ) {
                    console.log('ERROR: ' + res.data.message );
                    return;
                }
                
                this.units = res.data.data;
            }
            catch(error) {
                this.loaded = true;
                console.log(error);
            }
        },
        async loadFromServer( unit ) {
            this.unit = unit;
            try {
                let api = new API();

                this.loaded = false;
                let res = await api.get( '/admin/unit/account/' + this.unit.unitNumber );
                this.loaded = true;

                if( !res.data.status && res.data.error ) {
                    alert('ERROR: ' + res.data.message );
                    return;
                }
                
                // this.residentId = residentId;
                this.paymentsAvailable = res.data.data.paymentsAvailable;
            }
            catch(error) {
                this.loaded = true;
                console.log(error);
            }
        },
        cancel() {
            this.$emit('cancel');
            this.payment = {
                date: new Date().toISOString().substr(0, 10),
                method: '',
                confirmation: '',
                applyTo: [],
                amount: 0,
            };
            this.unit = null;
            this.residentId = 0;
            this.paymentsAvailable = [];
            this.unitError = this.dateError = this.paymentMethodError = this.confirmationError = this.applyToError = '';
            
            this.showAlert = false;
        },
        toggleSelectAll() {
            this.$nextTick(() => {
                if( this.allPaymentsSelected ) {
                    this.payment.applyTo = [];
                } else {
                    this.payment.applyTo = _st.extractProp( this.paymentsAvailable , 'id' ); //.slice();
                }
            });
        },
        toggleItem(item) {
            console.log(item);
            if( !this.isSelected(item) ) 
                this.payment.applyTo.push(item);
            else
                this.payment.applyTo.splice( this.payment.applyTo.indexOf(item), 1);
        },
        isSelected(item) {
            let found = this.payment.applyTo.find(p => {
                return p === item;
            });

            return found != undefined && found != null;
        },
        customFilter(item, queryText) {
            const textOne = item.unitNumber.toLowerCase();
            const textTwo = item.buildingType.toLowerCase();
            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
        },
        isValid() {
            let errorFound = false;
            this.unitError = this.dateError = this.paymentMethodError = this.confirmationError = this.applyToError = '';
            
            if( _st.isNUE( this.unit ) ) {
                this.unitError = 'required';
                errorFound = true;
            }
            if( _st.isNUE( this.payment.date == null ) ) {
                this.dateError = 'required';
                errorFound = true;
            }
            if( _st.isNUE( this.payment.method == null ) ) {
                this.paymentMethodError = 'required';
                errorFound = true;
            }
            if( this.payment.applyTo.length == 0 ) {
                this.applyToError = 'You must select at least one payment';
                errorFound = true;
            }

            return !errorFound;
            
        },
        async processPayment() {
            if( !this.isValid() )
                return;

            try {
                let api = new API();

                this.saving = true;
                let res = await api.post('/admin/unit/process-payment', {
                    buildingTypeId: this.unit.buildingTypeId,
                    unit: this.unit.unitNumber,
                    date: this.payment.date,
                    method: this.payment.method,
                    confirmation: this.payment.confirmation,
                    applyTo: this.payment.applyTo
                });

                this.saving = false;
                if( !res.data.status && res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'error';
                    this.showAlert = true;
                    return;
                }

                if( !res.data.status && !res.data.error ) {
                    this.alertMessage = res.data.message;
                    this.alertColor = 'warning';
                    this.showAlert = true;
                    return;
                }

                this.$emit('processed');

                // show confirmation message
                
                this.cancel();
            }
            catch(error) {
                this.saving = false;
                console.log(error);
            }
        },
        parseDate( dateFormatted ) {
            if (!dateFormatted) return null;

            const [month, day, year] = dateFormatted.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
    },
}
</script>

<style scoped>

</style>