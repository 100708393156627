import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        meta: { isLoginPage: true, requiresAuth: false },
        component: () => import('./views/Login.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: { requiresAuth: true },
        component: () => import('./views/Dashboard.vue')
    },
    {
        path: '/events',
        name: 'events',
        meta: { requiresAuth: true },
        component: () => import('./views/Events.vue')
    },
    {
        path: '/organizations',
        name: 'Organizations',
        meta: { requiresAuth: true },
        component: () => import('./views/Organizations.vue')
    },
    {
        path: '/residents',
        name: 'Residents',
        meta: { requiresAuth: true },
        component: () => import('./views/Residents.vue')
    },
    {
        path: '/amenities',
        name: 'Amenities',
        meta: { requiresAuth: true },
        component: () => import('./views/Amenities.vue')
    },
    {
        path: '/settings',
        name: 'Settings',
        meta: { requiresAuth: true },
        component: () => import('./views/Settings.vue')
    },
    {
        path: '/reports',
        name: 'Reports',
        meta: { requiresAuth: true },
        component: () => import('./views/Reports.vue')
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    // await store.dispatch('auth/authAction');

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const isLoggedIn = store.getters['auth/isLoggedIn'];

    // console.log( requiresAuth, isLoggedIn, from.fullPath, to.fullPath );

    if( requiresAuth ) {
        if( isLoggedIn ) {
            if( store.getters['auth/location']?.blockMissingPayment && to.path !== '/settings' ) {
                next('/settings');
            }
            else {
                next();
            }
        }
        else {
            // go to login page
            next('/');
        }
    }
    else {
        next();
    }
})

router.afterEach((to) => {
    Vue.nextTick(() => { document.title = to.name ? `Urbi Admin Panel | ${to.name}` : 'Urbi Admin Panel' });
});

export default router;