import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth/auth';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth
    }
});

// export default new Vuex.Store({
//     state: {

//         app: {
//             title: 'Urbi Admin Panel',
//             version: '1.0.0'
//         },

//         user: 0,
//         location: 0,
//         isMobile: false,
//         isDesktop: false,
//         showMessage: false,
//         messageTimeout: 6000,
//         messageColor: 'error',
//         messageText: '',
//         authToken: ''
//     },

//     mutations: {
//         LOGIN( state, payload ) {
//             console.log('LOGIN', payload);
//             state.user = payload.user;
//             state.authToken = payload.token;
//             state.location = payload.location;
//             router.push('/dashboard');
//         },
//         LOGOUT( state ) {
            
//             state.user = 0;
//             state.authToken = '';

//             router.push('/');
//         },
//         SHOW_LOGIN( state ) {
//             state.showLogin = true;
//         },
//         LOCATION( state, payload ) {
//             state.location = payload;
//         },
//         SHOW_MESSAGE( state, payload ) {
//             state.messageText = payload.message;
//             state.messageColor = payload.color;
//             state.messageTimeout = payload.timeout == null ? 6000 : payload.timeout;
//             state.showMessage = true;
//         },
//         CLOSE_MESSAGE( state ) {
//             state.showMessage = false;
//         }
//         // SHOW_COMMUNITY_MESSAGE( state ) {
//         //     state.communityMsgDialog = true;
//         // },
//         // HIDE_COMMUNITY_MESSAGE( state ) {
//         //     state.communityMsgDialog = false;
//         // },
//     },
//     actions: {
//         login( context, token ) {
//             if( _st.isNUE( token ) )
//                 return;

//             // extract user object from token and save it in the store
//             var jwt = require('jsonwebtoken');
//             let decoded = jwt.decode(token, {complete: true});

//             console.log(decoded.payload.usr);
//             if( _st.isNU( decoded.payload.usr ) ) {
//                 return;
//             }

//             Vue.prototype.$http = axios.create({
//                 baseURL: process.env.NODE_ENV === 'production' ? 'https://api.softechpr.com/myurb/v1/index.php' : 'http://10.5.18.101/urbi/api/v1/index.php',
//                 withCredentials: false,
//                 headers: {
//                     Authorization: '6c547dcf-3074-11e9-9130-000c29c44ec0,Bearer ' + token
//                 },
//                 validateStatus: function (status) {
//                     if (status >= 200 && status < 300 && status !== 204) {
//                         return true
//                     } else {
//                         return false
//                     }
//                 }
//             });

//             context.commit( 'LOGIN', {
//                 user        : decoded.payload.usr,
//                 location    : decoded.payload.loc,
//                 authToken   : token
//             });
//         },
//         logout( context ) {
//             context.commit( 'LOGOUT' );
      
//             Vue.prototype.$http = axios.create({
//                 baseURL: process.env.NODE_ENV === 'production' ? 'https://api.softechpr.com/myurb/v1/index.php' : 'http://10.5.18.101/urbi/api/v1/index.php',
//                 withCredentials: false,
//                 headers: {
//                     Authorization: '6c547dcf-3074-11e9-9130-000c29c44ec0'
//                 },
//                 validateStatus: function (status) {
//                     if (status >= 200 && status < 300 && status !== 204) {
//                         return true
//                     } else {
//                         return false
//                     }
//                 }
//             });
//         },
//         showLogin( context ) {
//             context.commit( 'SHOW_LOGIN' );
//         },
//         location( context, location ) {
//             context.commit( 'LOCATION', location );
//         },
//         showMessage: function( context, parameters ) {
//             context.commit( 'SHOW_MESSAGE', parameters);
//         },
//         closeMessage: function( context ) {
//             context.commit( 'CLOSE_MESSAGE' );
//         },
//         setAxiosDefaults: function( context ) {
//             if( context.getters.isUserLoggedIn ) {
//                 Vue.prototype.$http = axios.create({
//                     baseURL: process.env.NODE_ENV === 'production' ? 'https://api.softechpr.com/myurb/v1/index.php' : 'http://10.5.18.101/urbi/api/v1/index.php',
//                     withCredentials: false,
//                     headers: {
//                         Authorization: '6c547dcf-3074-11e9-9130-000c29c44ec0,Bearer ' + context.state.authToken
//                     },
//                     validateStatus: function (status) {
//                         if (status >= 200 && status < 300 && status !== 204) {
//                             return true
//                         } else {
//                             return false
//                         }
//                     }
//                 });
//             }
//             else {
//                 Vue.prototype.$http = axios.create({
//                     baseURL: process.env.NODE_ENV === 'production' ? 'https://api.softechpr.com/myurb/v1/index.php' : 'http://10.5.18.101/urbi/api/v1/index.php',
//                     withCredentials: false,
//                     headers: {
//                         Authorization: '6c547dcf-3074-11e9-9130-000c29c44ec0'
//                     },
//                     validateStatus: function (status) {
//                         if (status >= 200 && status < 300 && status !== 204) {
//                             return true
//                         } else {
//                             return false
//                         }
//                     }
//                 });
//             }
//         },
//         // showCommunityMessageDialog( context ) {
//         //     context.commit( 'SHOW_COMMUNITY_MESSAGE' );
//         // },
//         // hideCommunityMessageDialog( context ) {
//         //     context.commit( 'HIDE_COMMUNITY_MESSAGE' );
//         // },
//     },
//     getters: {
//         user( state ) {
//             return state.user;
//         },
//         templateUser( state ) {
//             if( state.user == null ) {
//                 return {
//                     id: 0,
//                     username: '',
//                     firstName: '',
//                     lastName: '',
//                     fullName: '',
//                     email: '',
//                     phone: '',
//                     lastLogin: null,
//                     locations: 0,
//                     time: null,
//                     locationId: 0
//                 }
//             }

//             return state.user;
//         },
//         isUserLoggedIn( state ) {
//             console.log( state.authToken );
//             console.log( state.user );
//             if( _st.isNUE( state.authToken ) || _st.isNUE( state.user ) )
//                 return false;

//             return true;
//         },
//         isMobile() {
//             return window.innerWidth < 600;
//         },
//         isDesktop() {
//             return this.$vuetify.breakpoint.lgAndUp;
//         },
//         location( state ) {
//             return state.location;
//         },
//         getAuthToken( state ) {
//             return state.authToken;
//         },
//         message( state ) {
//             return {
//               text: state.messageText,
//               color: state.messageColor,
//               timeout: state.messageTimeout,
//               show: state.showMessage
//             }
//         },
//         // communityMsgDialog( state ) {
//         //     return state.communityMsgDialog;
//         // }
//     },
//     plugins: [createPersistedState()],
// })