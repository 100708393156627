<template>
  <v-app>
    <div v-if="$route.meta != undefined && $route.meta.isLoginPage" class="fill-height" style="width:100%;">
      <!-- <div id="loginPageBackground"></div> -->
      <router-view></router-view>
    </div>
    <div v-else>
      <v-app-bar dark v-if="isMobile" app>
        <v-app-bar-nav-icon @click="showDrawer = !showDrawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer permanent width="15%" style="margin-left: 56px;" v-if="isDesktop" app>
        <v-row class="fill-height" no-gutters>
          <v-list class="grow" v-show="!isMobile">
            <v-skeleton-loader type="list-item-two-line" :loading="location == null" transition="fade-transition">
              <v-list-item two-line>
                <v-list-item-content class="px-2 py-1 mb-2" v-if="location != null"> <!-- style="background-color: #ededed;border-radius:5px;" -->
                  <v-list-item-title class="title">{{ location.name }}</v-list-item-title>
                  <v-list-item-subtitle class="caption">{{ location.totalUnits }} {{ location.totalUnits == 1 ? 'unit' : 'units' }}</v-list-item-subtitle>
                </v-list-item-content>
                <!-- <v-list-item-action>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-list-item-action> -->
              </v-list-item>
            </v-skeleton-loader>
            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">Actions</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="mb-3">
              <v-list-item-content>
                  <v-row>
                    <v-col cols="4" class="my-0 text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn fab color="orange" class="white--text" small elevation="2" v-on="on" @click="newResidentDialog = true" :disabled="location.blockMissingPayment"><v-icon>mdi-account-plus</v-icon></v-btn>
                        </template>
                        <span>Add new resident</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="4" class="my-0 text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn fab color="blue" class="white--text" small elevation="2" v-on="on" @click="newEventDialog = true" :disabled="location.blockMissingPayment"><v-icon>mdi-calendar-plus</v-icon></v-btn>
                        </template>
                        <span>Add new event</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="4" class="my-0 text-center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn fab color="green" class="white--text" small elevation="2" v-on="on" @click="communityMsgDialog = true" :disabled="location.blockMissingPayment"><v-icon>mdi-message-plus-outline</v-icon></v-btn>
                        </template>
                        <span>Post community message</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
              </v-list-item-content>
            </v-list-item>

            <template v-for="item in actionsMenu">
              <v-list-item :key="item.id" @click="item.action" :disabled="location.blockMissingPayment">
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>

            <!-- <v-list-item style="position:fixed;bottom:0;width:85%;" class="mb-2">
              <v-btn block depressed color="#f47322" class="white--text muli">Post Community Message <v-icon right>mdi-message-plus-outline</v-icon></v-btn>
            </v-list-item> -->
          </v-list>
        </v-row>
      </v-navigation-drawer>
      <v-navigation-drawer dark :mini-variant="!isMobile" mini-variant-width="56" :permanent="!isMobile" class="drawer-gradient" v-model="showDrawer" app>
        <v-list-item class="px-2" v-if="user != null">
          <v-list-item-avatar>
            <v-menu v-model="profileMenu" :close-on-content-click="false" transition="slide-x-transition" attach="#app">
              <template v-slot:activator="{ on }">
                <v-avatar v-on="on" v-show="!isMobile" color="blue-grey" class="white--text" size="40">{{ user.firstName[0] + user.lastName[0] }}</v-avatar>
                <v-list dense nav dark v-show="isMobile" class="py-0">
                  <v-list-item width="100%">
                    <v-list-item-avatar>
                      <v-avatar v-show="isMobile" color="blue-grey" class="white--text" size="40">{{ user.firstName[0] + user.lastName[0] }}</v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                      <v-list-item-subtitle>Administrator</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>

              <v-card top>
                <v-list class="pt-0">
                  <v-list-item class="pt-0">
                    <v-list-item-avatar>
                      <v-avatar color="blue-grey" class="white--text" size="40">{{ user.firstName[0] + user.lastName[0] }}</v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                      <v-list-item-subtitle>Administrator</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon @click="logoutDialog = true">
                        <v-icon>mdi-logout-variant</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>

                <v-divider></v-divider>

                <v-subheader>Change password</v-subheader>
                <change-password dense ref="pwd" @login="changeUserPassword"></change-password>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="warning" text @click="changeUserPassword" :loading="changingPwd">Change</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-list-item-avatar>
        </v-list-item>

        <v-list dense class="px-0 side-bar">

          <v-list-item v-for="item in items" :key="item.title" :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-list dense class="px-0 side-bar">
            <v-list-item v-for="item in accountItems" :key="item.title" :to="item.to">
              <v-list-item-action>
                <v-badge :value="location != null && location.blockMissingPayment" color="red" dot>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>

      <v-content app class="grey lighten-3">
        <v-container fluid>
          <!-- <super-seach /> -->
          <router-view style="height:100%;"></router-view>
        </v-container>
      </v-content>

      <v-dialog v-model="communityMsgDialog" persistent min-width="500px" max-width="700px">
        <v-card>
          <v-card-title>Community message</v-card-title>
          <v-card-subtitle>Post new community message. This will produce a push notification and it will appear on everyone's wall.</v-card-subtitle>
          <v-card-text>
            <v-textarea v-model="communityMessage" outlined placeholder="Message" :error-messages="communityMessageError"></v-textarea>
            <div class="text-right">
              <image-picker v-model="communityMessagePics" multiple primary-image ref="imagePicker"></image-picker>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="hideCommunityMsgDialog">Cancel</v-btn>
            <v-btn text color="primary" @click="postCommunityMessage" :loading="postingMessage">Post</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <resident ref="resident" :dialog="true" :dialogOpen="newResidentDialog" @cancel="newResidentDialog = false"></resident>
      <!-- <event ref="event" :dialog="true" :dialogOpen="newEventDialog" @cancel="newEventDialog = false"></event> -->
      <new-resident-payment ref="residentPayment" :dialog="true" :dialogOpen="newPaymentDialog" @cancel="newPaymentDialog = false" v-if="isUserLoggedIn"></new-resident-payment>
      <amenity ref="amenity" :dialog="true" :dialogOpen="newAmenityDialog" @cancel="newAmenityDialog = false"
                  dialogTitle="New Amenity" dialogSubtitle="Add new amenity to your community"></amenity>
      <event ref="event" :dialog="true" dialogTitle="New Event" dialogSubtitle="Configure your event" :dialogOpen="newEventDialog" 
                  @cancel="newEventDialog = false"></event>

      <!-- <v-snackbar v-model="showMessageSnackbar" top right :timeout="$store.getters.message.timeout" :color="$store.getters.message.color">
            {{ $store.getters.message.text }}
        <v-btn icon @click="showMessageSnackbar = false">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar> -->

      <v-dialog v-model="logoutDialog" width="400">
        <v-card>
          <v-card-title>Warning</v-card-title>
          <v-card-text>Are you sure you want to logout?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="logoutDialog = false">Cancel</v-btn>
            <v-btn text color="error" @click="logout">Logout</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <alerts />
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Resident from '@/components/Resident.vue';
import Amenity from '@/components/Amenity.vue';
import Event from '@/components/Event.vue';
import ChangePassword from '@/components/ChangePassword.vue';
import NewResidentPayment from '@/components/NewResidentPayment.vue';
import ImagePicker from '@/components/ImagePicker.vue';
import { API } from '@/inc/api';
import Alerts from '@/components/Alerts.vue';

export default {
  data () {
    return {
      title: 'Urbi App Admin Panel',
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/dashboard' },
        { title: 'Residents', icon: 'mdi-account-group', to: '/residents' },
        { title: 'Organizations', icon: 'mdi-sitemap', to: '/organizations' },
        { title: 'Events', icon: 'mdi-calendar', to: '/events' },
        { title: 'Amenities', icon: 'mdi-balloon', to: '/amenities' },
        { title: 'Reports', icon: 'mdi-finance', to: '/reports' },
      ],
      accountItems: [
        { title: 'Settings', icon: 'mdi-cog-outline', to: '/settings' },
      ],
      actionsMenu: [
        { id: 1, text: 'Apply resident payment', icon: 'mdi-cash-multiple', action: this.openPaymentDialog },
        { id: 2, text: 'Add new amenity', icon: 'mdi-office-building', action: this.openAmenityDialog }
      ],
      mini: true,
      profileMenu: false,
      locationLoaded: false,
      communityMsgDialog: false,
      communityMessage: '',
      communityMessageError: '',
      communityMessagePics: [],
      communityMessagePicsURI: [],
      communityMessagePicsError: '',
      postingMessage: false,
      newResidentDialog: false,
      newEventDialog: false,
      newPaymentDialog: false,
      newAmenityDialog: false,
      isMobile: false,
      isDesktop: true,
      showDrawer: true,
      showMessageSnackbar: false,
      logoutDialog: false,
      changingPwd: false,
    }
  },
  computed: {
    ...mapGetters({
      user            : 'auth/user',
      isUserLoggedIn  : 'auth/isLoggedIn',
      location        : 'auth/location'
    })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  async created() {
    await this.authAction();

    // if( !this.isUserLoggedIn )
    //   this.$router.push('/');
  },
  mounted() {
    document.title = this.title;
    // this.$router.push('/');

    // this.$store.watch(
    //   (state, getters) => getters.message,
    //   (newValue) => {
    //     if( newValue.show ) {
    //       this.showMessageSnackbar = true;
    //       this.$store.dispatch('closeMessage');
    //     }
    //   },
    // );

    this.init();
  },
  methods: {
    ...mapActions({
      authAction: 'auth/authAction',
      logoutApp : 'auth/logout'
    }),
    async init() {
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true });
    },
    hideCommunityMsgDialog() {
      this.communityMsgDialog = false;
      this.communityMessage = '';
      this.communityMessageError = '';
      this.communityMessagePics = [];
    },
    async postCommunityMessage() {
      // validate
      let errorFound = false;
      this.communityMessageError = this.communityMessagePicsError = '';
      if( this.communityMessage.length == 0 ) {
        this.communityMessageError = 'required';
        errorFound = true;
      }
      if( this.communityMessagePics.length > 5 ) {
        this.communityMessagePicsError = 'There is a limit of 5 images per post';
        errorFound = true;
      }

      if( errorFound )
        return;

      let api = new API(); 

      this.postingMessage = true;
      let res = await api.post('/admin/community-message', {
        text    : this.communityMessage,
        gallery : this.communityMessagePics
      });

      this.postingMessage = false;
      if( !res.data.status && res.data.error ) {
        this.mxShowMessage( res.data.message, 'error' );
        return;
      }

      this.hideCommunityMsgDialog();

      // show confirmation message
      this.mxShowMessage( 'Message posted', 'success' );
    },
    async changeUserPassword() {
      if( !this.$refs.pwd.isFormValid() ) 
        return;

      let api = new API();

      try {
        this.changingPwd = true;
        let res = await api.post('/admin/change-pwd',{
          currentPassword: this.$refs.pwd.oldPassword,
          password: this.$refs.pwd.newPassword
        });
        this.changingPwd = false;

        if( !res.data.status ) {
          this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
          return;
        }

        this.$refs.pwd.clear();
        this.profileMenu = false;

        // show confirmation message
        this.mxShowMessage( 'Password changed', 'success' );
      }
      catch(error) {
        this.changingPwd = false;
        console.log(error);
      }
    },
    logout() {
      this.logoutApp();
      this.logoutDialog = false;
      this.profileMenu = false;
    },
    encodeImageFileAsURL(element) {
      var file = element;
      var reader = new FileReader();
      reader.onloadend = () => {
        // window.console.log('RESULT', reader.result);
        this.communityMessagePicsURI.push(reader.result);
      }
      reader.readAsDataURL(file);
    },
    openPaymentDialog() {
      this.newPaymentDialog = true;
    },
    openAmenityDialog() {
      this.newAmenityDialog = true;
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      this.isDesktop = this.$vuetify.breakpoint.lgAndUp;
    },
  },
  components: {
    Resident, Amenity, Event, ChangePassword, NewResidentPayment, ImagePicker, Alerts
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Muli|Roboto&display=swap');
#app .roboto {
  font-family: 'Roboto', sans-serif;  /* 'Muli', sans-serif; */
}
#app .muli {
  font-family: 'Muli', sans-serif;
}
#app .side-bar .v-list-item--active {
  border-left: 4px solid #ff9800;
}
#app #loginPageBackground {
  background-image: url('assets/condominium.jpeg');
  background-size: cover;
  
  /* -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); */

  position: absolute;
  width:100%;
  height: 100%;
}
</style>